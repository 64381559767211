<template>
  <b-card>
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0 bold">Out of Office</h3>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col cols="12">
        <h3>Duration</h3>
        <p>How long will you be out of office?</p>

        <div class="tw-my-8">
          <div class="tw-flex tw-flex-col md:tw-flex-row">
            <div class="tw-flex-auto">
              <b-form-datepicker
                :date-disabled-fn="dateDisabled"
                v-model="startdate"
              ></b-form-datepicker>
              <b-form-timepicker
                minutes-step="15"
                v-model="starttime"
              ></b-form-timepicker>
            </div>
            <div class="tw-flex tw-items-center tw-justify-center tw-mx-8">
              <div class="tw-hidden md:tw-block">
                <svg
                  width="26"
                  height="14"
                  viewBox="0 0 26 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.25L26 7.25"
                    stroke="#BDBDBD"
                    stroke-width="1.2"
                  />
                  <path
                    d="M20 13.25C20 11.25 22.0001 7.75001 26 7.75001"
                    stroke="#BDBDBD"
                    stroke-width="1.2"
                  />
                  <path
                    d="M20 0.75C20 2.75 22.0001 6.74999 26 6.74999"
                    stroke="#BDBDBD"
                    stroke-width="1.2"
                  />
                </svg>
              </div>
              <span class="tw-block tw-py-6 md:tw-hidden"> TO </span>
            </div>
            <div class="tw-flex-auto">
              <b-form-datepicker
                :date-disabled-fn="dateDisabled"
                v-model="enddate"
              ></b-form-datepicker>
              <b-form-timepicker
                minutes-step="15"
                v-model="endtime"
              ></b-form-timepicker>
            </div>
          </div>

          <div class="tw-flex tw-justify-end">
            <b-button
              style="min-width: 150px"
              pill
              variant="primary"
              class="tw-mt-6"
              :disabled="
                isProcessing ||
                !(
                  Boolean(startdate) &&
                  Boolean(enddate) &&
                  validateDateTime(startdate, starttime, enddate, endtime)
                )
              "
              @click="saveTimeOffRequest"
            >
              <b-spinner v-if="isProcessing" small></b-spinner>
              <span v-else>Save</span>
            </b-button>
          </div>
        </div>

        <b-table responsive :items="items" :fields="fields" show-empty>
          <template #head(action)>
            <a
              class="
                tw-capitalize
                tw-cursor-pointer
                tw-inline-block
                tw-mr-4
                tw-tracking-wide
              "
              :class="{ 'tw-pointer-events-none': isProcessing }"
              @click="toggleCompleteItems"
            >
              <b-spinner v-if="isFetching" small></b-spinner>
              <span v-else>
                {{ showActive ? "Show Complete" : "Hide Complete" }}
              </span>
            </a>
          </template>
          <template #cell(action)="data">
            <div>
              <a
                v-if="
                  ['PENDING'].includes(data.item.request.reassignmentstatus)
                "
                class="tw-capitalize tw-cursor-pointer tw-inline-block tw-mr-4"
                @click="showEditModal(data.item.request)"
              >
                Edit
              </a>
              <a
                v-if="
                  ['PENDING'].includes(data.item.request.reassignmentstatus)
                "
                class="tw-capitalize tw-cursor-pointer"
                @click="showDeleteModal(data.item.request.id)"
              >
                Delete
              </a>
              <a
                v-if="
                  ['APPROVED', 'TRANSFERRED'].includes(
                    data.item.request.reassignmentstatus
                  )
                "
                class="tw-capitalize tw-cursor-pointer"
                @click="showCancelModal(data.item.request.id)"
              >
                Cancel
              </a>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal
      id="deletetimeoff"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-row style="position: relative">
        <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="8" offset-sm="2" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            Deleting Time-off Request
          </h1>
          <p class="mb-4">
            Are you sure you want to delete your time off request?
          </p>
          <b-row class="mb-3">
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                @click="$bvModal.hide('deletetimeoff')"
                variant="primary"
              >
                Nevermind
              </b-button>
            </b-col>
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                :disabled="isProcessing"
                @click="onDeleteRequest"
                variant="outline-danger"
              >
                <b-spinner v-if="isProcessing" small></b-spinner>
                <span v-else>Confirm</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="canceltimeoff"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-row style="position: relative">
        <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="8" offset-sm="2" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            Cancelling Time-off Request
          </h1>
          <p class="mb-4">
            Are you sure you want to cancel your time off request?
          </p>
          <b-row class="mb-3">
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                @click="$bvModal.hide('canceltimeoff')"
                variant="primary"
              >
                Nevermind
              </b-button>
            </b-col>
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                :disabled="isProcessing"
                @click="onCancelRequest"
                variant="outline-danger"
              >
                <b-spinner v-if="isProcessing" small></b-spinner>
                <span v-else>Confirm</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="edittimeoff"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
    >
      <b-row style="position: relative">
        <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" class="py-6 tw-px-8">
          <h1 class="heading mb-4" style="line-height: 30px">
            Edit Time-off Request
          </h1>
          <div class="tw-mt-8">
            <div class="tw-flex">
              <b-form-datepicker
                :date-disabled-fn="dateDisabled"
                v-model="editedstartdate"
                :disabled="selectedDoctorReassignmentStatus === 'TRANSFERRED'"
              ></b-form-datepicker>
              <b-form-timepicker
                minutes-step="15"
                v-model="editedstarttime"
                :disabled="selectedDoctorReassignmentStatus === 'TRANSFERRED'"
              ></b-form-timepicker>
              <div class="tw-flex tw-items-center tw-justify-center tw-mx-8">
                <svg
                  width="26"
                  height="14"
                  viewBox="0 0 26 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.25L26 7.25"
                    stroke="#BDBDBD"
                    stroke-width="1.2"
                  />
                  <path
                    d="M20 13.25C20 11.25 22.0001 7.75001 26 7.75001"
                    stroke="#BDBDBD"
                    stroke-width="1.2"
                  />
                  <path
                    d="M20 0.75C20 2.75 22.0001 6.74999 26 6.74999"
                    stroke="#BDBDBD"
                    stroke-width="1.2"
                  />
                </svg>
              </div>
              <b-form-datepicker
                :date-disabled-fn="dateDisabled"
                v-model="editedenddate"
              ></b-form-datepicker>
              <b-form-timepicker
                minutes-step="15"
                v-model="editedendtime"
              ></b-form-timepicker>
            </div>

            <b-row class="mb-3 tw-mt-10">
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  @click="$bvModal.hide('edittimeoff')"
                  variant="primary"
                >
                  Nevermind
                </b-button>
              </b-col>
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  variant="outline-primary"
                  :disabled="
                    isProcessing ||
                    !(
                      Boolean(editedstartdate) &&
                      Boolean(editedenddate) &&
                      validateDateTime(
                        editedstartdate,
                        editedstarttime,
                        editedenddate,
                        editedendtime
                      )
                    )
                  "
                  @click="onEditRequest"
                >
                  <b-spinner v-if="isProcessing" small></b-spinner>
                  <span v-else>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-toast
      solid
      toaster="b-toaster-top-center"
      id="outOfOfficeToast"
      :variant="toast.variant"
      :title="toast.title"
    >
      {{ toast.message }}
    </b-toast>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  sub,
  getUnixTime,
  fromUnixTime,
  parse,
  format,
  isBefore,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
export default {
  name: "TimeOff",
  data() {
    return {
      toast: {
        title: "",
        message: "",
        variant: "",
      },
      isProcessing: false,
      isFetching: false,
      startdate: format(new Date(), "yyyy-MM-dd"),
      starttime: "00:00:00",
      enddate: "",
      endtime: "00:00:00",
      fields: [
        {
          key: "startdate",
          label: "Start date",
          sortable: false,
        },
        {
          key: "enddate",
          label: "End date",
          sortable: false,
        },
        {
          key: "status",
          label: "Request Status",
          sortable: false,
        },
        {
          key: "reassignmentstatus",
          label: "Assignment Status",
          sortable: false,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
      items: [],

      selectedDoctorRequestId: "",
      selectedDoctorReassignmentStatus: "",
      editedstartdate: format(new Date(), "yyyy-MM-dd"),
      editedstarttime: "00:00:00",
      editedenddate: "",
      editedendtime: "00:00:00",

      showActive: true,
    };
  },
  created() {
    this.getReassignmentRequest();
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    showToast(title, message, variant = "success") {
      this.toast.title = title;
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show("outOfOfficeToast");
    },
    dateDisabled(ymd, date) {
      return sub(new Date(), { days: 1 }) > date;
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    parseDateToUnix(date, time) {
      if (date) {
        return getUnixTime(
          parse(`${date} ${time}`, "yyyy-MM-dd HH:mm:ss", new Date())
        );
      } else {
        return null;
      }
    },
    async getReassignmentRequest() {
      this.isFetching = true;
      const data = await this.$store.dispatch(
        "account/getReassignmentRequest",
        { showActive: this.showActive }
      );
      this.isFetching = false;
      this.items = data.map((item) => ({
        startdate: this.parseDate(item.startdatestamp, "MM/dd/yy hh:mm aa"),
        enddate: item.enddatestamp
          ? this.parseDate(item.enddatestamp, "MM/dd/yy hh:mm aa")
          : "-",
        status: item.requeststatus,
        reassignmentstatus: item.reassignmentstatus,
        request: item,
      }));
    },
    validateDateTime(startdate, starttime, enddate, endtime) {
      if (
        this.parseDateToUnix(startdate, starttime) &&
        this.parseDateToUnix(enddate, endtime)
      ) {
        return isBefore(
          this.parseDateToUnix(startdate, starttime),
          this.parseDateToUnix(enddate, endtime)
        );
      } else {
        return false;
      }
    },
    async saveTimeOffRequest() {
      if (
        this.validateDateTime(
          this.startdate,
          this.starttime,
          this.enddate,
          this.endtime
        )
      ) {
        try {
          this.isProcessing = true;

          await this.$store.dispatch("account/requestDoctorReassignment", {
            data: {
              startdatestamp: this.getUnixTimeFromDate(this.startdate),
              ...(this.enddate && {
                enddatestamp: this.getUnixTimeFromDate(this.enddate),
              }),
            },
          });
          await this.getReassignmentRequest();

          this.isProcessing = false;
        } catch (e) {
          console.error(e);
          this.showToast("Something went wrong", e.message, "danger");
          this.isProcessing = false;
        }
      }
    },
    showDeleteModal(doctorrequestid) {
      this.$bvModal.show("deletetimeoff");
      this.selectedDoctorRequestId = doctorrequestid;
    },
    async onDeleteRequest() {
      try {
        this.isProcessing = true;

        await this.$store.dispatch(
          "account/removeDoctorReassignment",
          this.selectedDoctorRequestId
        );
        await this.getReassignmentRequest();
        this.$bvModal.hide("deletetimeoff");
        this.isProcessing = false;
      } catch (e) {
        console.error(e);
        this.showToast("Something went wrong", e.message, "danger");
        this.isProcessing = false;
      }
    },
    showCancelModal(doctorrequestid) {
      this.$bvModal.show("canceltimeoff");
      this.selectedDoctorRequestId = doctorrequestid;
    },
    async onCancelRequest() {
      try {
        this.isProcessing = true;

        await this.$store.dispatch(
          "account/cancelDoctorReassignment",
          this.selectedDoctorRequestId
        );
        await this.getReassignmentRequest();
        this.$bvModal.hide("canceltimeoff");
        this.isProcessing = false;
      } catch (e) {
        console.error(e);
        this.showToast("Something went wrong", e.message, "danger");
        this.isProcessing = false;
      }
    },
    showEditModal(request) {
      this.$bvModal.show("edittimeoff");
      this.selectedDoctorRequestId = request.id;
      this.selectedDoctorReassignmentStatus = request.reassignmentstatus;
      this.editedstartdate = format(
        fromUnixTime(request.startdatestamp),
        "yyyy-MM-dd"
      );
      this.editedstarttime = format(
        fromUnixTime(request.startdatestamp),
        "HH:mm:ss"
      );

      if (request.enddatestamp) {
        this.editedenddate = format(
          fromUnixTime(request.enddatestamp),
          "yyyy-MM-dd"
        );
        this.editedendtime = format(
          fromUnixTime(request.enddatestamp),
          "HH:mm:ss"
        );
      } else {
        this.editedenddate = "";
        this.editedendtime = "00:00:00";
      }
    },
    async onEditRequest() {
      if (
        this.validateDateTime(
          this.editedstartdate,
          this.editedstarttime,
          this.editedenddate,
          this.editedendtime
        )
      ) {
        try {
          this.isProcessing = true;

          await this.$store.dispatch("account/editDoctorReassignment", {
            doctorrequestid: this.selectedDoctorRequestId,
            data: {
              startdatestamp: this.getDateStringWithCurrentTime(
                this.editedstartdate
              ),
              ...(this.editedenddate && {
                enddatestamp: this.getDateStringWithCurrentTime(
                  this.editedenddate
                ),
              }),
            },
          });
          await this.getReassignmentRequest();
          this.$bvModal.hide("edittimeoff");
          this.isProcessing = false;
        } catch (e) {
          console.error(e);
          this.showToast("Something went wrong", e.message, "danger");
          this.isProcessing = false;
        }
      }
    },
    toggleCompleteItems() {
      this.showActive = !this.showActive;
      this.getReassignmentRequest();
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
    getUnixTimeFromDate(date) {
      return getUnixTime(
        parse(
          this.getDateStringWithCurrentTime(date),
          "yyyy-MM-dd H:m:s",
          new Date()
        )
      );
    },
  },
};
</script>

<style>
@media (min-width: 769px) {
  #outOfOfficeToast__toast_outer {
    top: 60px !important;
  }

  .b-toaster-slot.vue-portal-target:has(#outOfOfficeToast__toast_outer) {
    left: 250px !important;
  }
}

.toast#outOfOfficeToast {
  max-width: none !important;
}
</style>

<style lang="scss" scoped>
label {
  color: #4f4f4f !important;
  font-size: 11px;
}

.card-header {
  border-bottom: 0;
  padding: 3rem 1.5rem;
}

.card-body {
  height: calc(100vh - 260px);
  overflow: auto;
}

@media (max-width: 768px) {
  .card-body {
    height: auto;
  }
}
</style>
