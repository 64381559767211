var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c("b-col", [
                    _c("h3", { staticClass: "mb-0 bold" }, [
                      _vm._v("Out of Office"),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("h3", [_vm._v("Duration")]),
              _c("p", [_vm._v("How long will you be out of office?")]),
              _c("div", { staticClass: "tw-my-8" }, [
                _c(
                  "div",
                  { staticClass: "tw-flex tw-flex-col md:tw-flex-row" },
                  [
                    _c(
                      "div",
                      { staticClass: "tw-flex-auto" },
                      [
                        _c("b-form-datepicker", {
                          attrs: { "date-disabled-fn": _vm.dateDisabled },
                          model: {
                            value: _vm.startdate,
                            callback: function ($$v) {
                              _vm.startdate = $$v
                            },
                            expression: "startdate",
                          },
                        }),
                        _c("b-form-timepicker", {
                          attrs: { "minutes-step": "15" },
                          model: {
                            value: _vm.starttime,
                            callback: function ($$v) {
                              _vm.starttime = $$v
                            },
                            expression: "starttime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-items-center tw-justify-center tw-mx-8",
                      },
                      [
                        _c("div", { staticClass: "tw-hidden md:tw-block" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "26",
                                height: "14",
                                viewBox: "0 0 26 14",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M0 7.25L26 7.25",
                                  stroke: "#BDBDBD",
                                  "stroke-width": "1.2",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M20 13.25C20 11.25 22.0001 7.75001 26 7.75001",
                                  stroke: "#BDBDBD",
                                  "stroke-width": "1.2",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M20 0.75C20 2.75 22.0001 6.74999 26 6.74999",
                                  stroke: "#BDBDBD",
                                  "stroke-width": "1.2",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "tw-block tw-py-6 md:tw-hidden" },
                          [_vm._v(" TO ")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "tw-flex-auto" },
                      [
                        _c("b-form-datepicker", {
                          attrs: { "date-disabled-fn": _vm.dateDisabled },
                          model: {
                            value: _vm.enddate,
                            callback: function ($$v) {
                              _vm.enddate = $$v
                            },
                            expression: "enddate",
                          },
                        }),
                        _c("b-form-timepicker", {
                          attrs: { "minutes-step": "15" },
                          model: {
                            value: _vm.endtime,
                            callback: function ($$v) {
                              _vm.endtime = $$v
                            },
                            expression: "endtime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "tw-flex tw-justify-end" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "tw-mt-6",
                        staticStyle: { "min-width": "150px" },
                        attrs: {
                          pill: "",
                          variant: "primary",
                          disabled:
                            _vm.isProcessing ||
                            !(
                              Boolean(_vm.startdate) &&
                              Boolean(_vm.enddate) &&
                              _vm.validateDateTime(
                                _vm.startdate,
                                _vm.starttime,
                                _vm.enddate,
                                _vm.endtime
                              )
                            ),
                        },
                        on: { click: _vm.saveTimeOffRequest },
                      },
                      [
                        _vm.isProcessing
                          ? _c("b-spinner", { attrs: { small: "" } })
                          : _c("span", [_vm._v("Save")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("b-table", {
                attrs: {
                  responsive: "",
                  items: _vm.items,
                  fields: _vm.fields,
                  "show-empty": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "head(action)",
                    fn: function () {
                      return [
                        _c(
                          "a",
                          {
                            staticClass:
                              "tw-capitalize tw-cursor-pointer tw-inline-block tw-mr-4 tw-tracking-wide",
                            class: {
                              "tw-pointer-events-none": _vm.isProcessing,
                            },
                            on: { click: _vm.toggleCompleteItems },
                          },
                          [
                            _vm.isFetching
                              ? _c("b-spinner", { attrs: { small: "" } })
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.showActive
                                          ? "Show Complete"
                                          : "Hide Complete"
                                      ) +
                                      " "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "cell(action)",
                    fn: function (data) {
                      return [
                        _c("div", [
                          ["PENDING"].includes(
                            data.item.request.reassignmentstatus
                          )
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "tw-capitalize tw-cursor-pointer tw-inline-block tw-mr-4",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditModal(
                                        data.item.request
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Edit ")]
                              )
                            : _vm._e(),
                          ["PENDING"].includes(
                            data.item.request.reassignmentstatus
                          )
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "tw-capitalize tw-cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDeleteModal(
                                        data.item.request.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Delete ")]
                              )
                            : _vm._e(),
                          ["APPROVED", "TRANSFERRED"].includes(
                            data.item.request.reassignmentstatus
                          )
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "tw-capitalize tw-cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showCancelModal(
                                        data.item.request.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "deletetimeoff",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
          },
        },
        [
          _c(
            "b-row",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "b-col",
                {
                  staticStyle: { position: "absolute", top: "0", left: "0" },
                  attrs: { cols: "12", sm: "5" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "30",
                        height: "27",
                        viewBox: "0 0 30 27",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                          fill: "#1A6A72",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "py-6",
                  attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [_vm._v(" Deleting Time-off Request ")]
                  ),
                  _c("p", { staticClass: "mb-4" }, [
                    _vm._v(
                      " Are you sure you want to delete your time off request? "
                    ),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("deletetimeoff")
                                },
                              },
                            },
                            [_vm._v(" Nevermind ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                disabled: _vm.isProcessing,
                                variant: "outline-danger",
                              },
                              on: { click: _vm.onDeleteRequest },
                            },
                            [
                              _vm.isProcessing
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _c("span", [_vm._v("Confirm")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "canceltimeoff",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
          },
        },
        [
          _c(
            "b-row",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "b-col",
                {
                  staticStyle: { position: "absolute", top: "0", left: "0" },
                  attrs: { cols: "12", sm: "5" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "30",
                        height: "27",
                        viewBox: "0 0 30 27",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                          fill: "#1A6A72",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "py-6",
                  attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [_vm._v(" Cancelling Time-off Request ")]
                  ),
                  _c("p", { staticClass: "mb-4" }, [
                    _vm._v(
                      " Are you sure you want to cancel your time off request? "
                    ),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("canceltimeoff")
                                },
                              },
                            },
                            [_vm._v(" Nevermind ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                disabled: _vm.isProcessing,
                                variant: "outline-danger",
                              },
                              on: { click: _vm.onCancelRequest },
                            },
                            [
                              _vm.isProcessing
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _c("span", [_vm._v("Confirm")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "edittimeoff",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "xl",
          },
        },
        [
          _c(
            "b-row",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "b-col",
                {
                  staticStyle: { position: "absolute", top: "0", left: "0" },
                  attrs: { cols: "12", sm: "5" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "30",
                        height: "27",
                        viewBox: "0 0 30 27",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                          fill: "#1A6A72",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "py-6 tw-px-8", attrs: { cols: "12" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [_vm._v(" Edit Time-off Request ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "tw-mt-8" },
                    [
                      _c(
                        "div",
                        { staticClass: "tw-flex" },
                        [
                          _c("b-form-datepicker", {
                            attrs: {
                              "date-disabled-fn": _vm.dateDisabled,
                              disabled:
                                _vm.selectedDoctorReassignmentStatus ===
                                "TRANSFERRED",
                            },
                            model: {
                              value: _vm.editedstartdate,
                              callback: function ($$v) {
                                _vm.editedstartdate = $$v
                              },
                              expression: "editedstartdate",
                            },
                          }),
                          _c("b-form-timepicker", {
                            attrs: {
                              "minutes-step": "15",
                              disabled:
                                _vm.selectedDoctorReassignmentStatus ===
                                "TRANSFERRED",
                            },
                            model: {
                              value: _vm.editedstarttime,
                              callback: function ($$v) {
                                _vm.editedstarttime = $$v
                              },
                              expression: "editedstarttime",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-flex tw-items-center tw-justify-center tw-mx-8",
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "26",
                                    height: "14",
                                    viewBox: "0 0 26 14",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M0 7.25L26 7.25",
                                      stroke: "#BDBDBD",
                                      "stroke-width": "1.2",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M20 13.25C20 11.25 22.0001 7.75001 26 7.75001",
                                      stroke: "#BDBDBD",
                                      "stroke-width": "1.2",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M20 0.75C20 2.75 22.0001 6.74999 26 6.74999",
                                      stroke: "#BDBDBD",
                                      "stroke-width": "1.2",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("b-form-datepicker", {
                            attrs: { "date-disabled-fn": _vm.dateDisabled },
                            model: {
                              value: _vm.editedenddate,
                              callback: function ($$v) {
                                _vm.editedenddate = $$v
                              },
                              expression: "editedenddate",
                            },
                          }),
                          _c("b-form-timepicker", {
                            attrs: { "minutes-step": "15" },
                            model: {
                              value: _vm.editedendtime,
                              callback: function ($$v) {
                                _vm.editedendtime = $$v
                              },
                              expression: "editedendtime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-3 tw-mt-10" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.hide("edittimeoff")
                                    },
                                  },
                                },
                                [_vm._v(" Nevermind ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    pill: "",
                                    block: "",
                                    variant: "outline-primary",
                                    disabled:
                                      _vm.isProcessing ||
                                      !(
                                        Boolean(_vm.editedstartdate) &&
                                        Boolean(_vm.editedenddate) &&
                                        _vm.validateDateTime(
                                          _vm.editedstartdate,
                                          _vm.editedstarttime,
                                          _vm.editedenddate,
                                          _vm.editedendtime
                                        )
                                      ),
                                  },
                                  on: { click: _vm.onEditRequest },
                                },
                                [
                                  _vm.isProcessing
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _c("span", [_vm._v("Save")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-toast",
        {
          attrs: {
            solid: "",
            toaster: "b-toaster-top-center",
            id: "outOfOfficeToast",
            variant: _vm.toast.variant,
            title: _vm.toast.title,
          },
        },
        [_vm._v(" " + _vm._s(_vm.toast.message) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }